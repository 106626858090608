<template>
    <div class="myDaYiList">
        <div class="detail_title">
            <span>答疑详情</span>
            <span style="margin-right:20px;cursor:pointer" @click="backList">返回列表</span>
        </div>

        <div class="content_box">
            <div class="title_box"> 
                <img :src="userInfo.headPic" />
                <span>{{userInfo.nickName}}</span>
                <span class="detail_types">追问</span>
            </div>
            <div class="question_box">{{dataobj.questionContent}}</div>
            
            <div  class="img_box" v-if="dataobj.questionPicList != null && dataobj.questionPicList != ''">
                <img class="detail_imgBox" v-for="resItem in dataobj.questionPicList" @click="imgViewFun(resItem)" :src="resItem" />
            </div>
            <span style="color: rgb(153, 153, 153);font-size: 12px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;display:block;text-align:right;margin-right:20px;">{{ dataobj.intime }}</span>
            <div class="content_bottom"></div>
           
            <div  class="title_box_amdin" v-if="dataobj.answerContent != null">
                <div>
                    <img v-if="dataobj.answerUserHeaderImgUrl != null && dataobj.answerUserHeaderImgUrl" :src="dataobj.answerUserHeaderImgUrl" />
                    <img v-else src="@/assets/answer/answerImg.jpeg" />
                    <span>超级管理员</span>
                    <span class="detail_types">回答</span>

                </div>
                <div>
                    <!-- <div class="zhuiwen_box">追问</div> -->
                </div>
                
            </div>
            <div class="question_box" ref="articleContent" v-html="dataobj.answerContent"></div>
            <span style="color: rgb(153, 153, 153);font-size: 12px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;display:block;text-align:right;margin-right:20px;">{{ dataobj.answertime }}</span>
            <div v-if="dataobj.answerContent != null" class="content_bottom"></div>
            
        </div>

        <div class="content_box" v-for="(item,index) in dataList">
            <div class="title_box"> 
                <img :src="userInfo.headPic" />
                <span>{{userInfo.nickName}}</span>
                <span class="detail_types">追问</span>
            </div>
            <div class="question_box_z">{{item.questionContent}}</div>
            
            <div  class="img_box" v-if="item.questionPics != null && item.questionPics != ''">
                <img class="detail_imgBox" v-for="resItem in getUrls(item.questionPics)" @click="imgViewFun(resItem)" :src="resItem" />
            </div>
            <span style="color: rgb(153, 153, 153);font-size: 12px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;display:block;text-align:right;margin-right:20px;">{{ item.intime }}</span>
            <div class="content_bottom"></div>
            <div  class="title_box_amdin" v-if="item.answerContent != null">
                <div>
                    <img v-if="item.answerUserHeaderImgUrl != null && item.answerUserHeaderImgUrl" :src="item.answerUserHeaderImgUrl" />
                    <img v-else src="@/assets/answer/answerImg.jpeg" />
                    <span>超级管理员</span>
                    <span class="detail_types">回答</span>

                </div>
                <div>
                    <div class="zhuiwen_box" v-if="isLaseAnswer == item.id" @click="zhuiWenClick(item)">追问</div>
                </div>
                
            </div>
            <div class="question_box_d" :ref="setItemRef"  v-html="item.answerContent"></div>
            <span style="color: rgb(153, 153, 153);font-size: 12px;font-family: SourceHanSansCN-Regular, SourceHanSansCN;display:block;text-align:right;margin-right:20px;">{{ item.answertime }}</span>
            <div v-if="item.answerContent != null" class="content_bottom"></div>
            
        </div>

        <el-dialog
            title="追问"
            :visible.sync="zhuiWenVisible"
            width="30%"
           >
            <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入您要提问的内容..."
            v-model="askTextarea"
            maxlength="300"
            resize="none"
            :show-word-limit="true"
            ></el-input>
            <fileUpload isList :options="{}" @fileCallback="fileBack"></fileUpload>
            <div class="videoRoom_main_right_content_noteView_btn">
               <el-button type="primary" @click="dayi_add()">提交</el-button>
            </div>
            <div style="width:100%;height:10px"></div>
        </el-dialog>

        <el-dialog
            title="图片预览"
            :visible.sync="imgViewVisible"
            width="70%"
           >
           <img  :src="imgViewSrc" style="width:100%;" />
            <div style="width:100%;height:10px"></div>
        </el-dialog>

        
       
    </div>
</template>

<script>
import { wodedayi,aqAdd } from "../../api/myself";
import fileUpload from "../../components/FileUpload.vue";
export default {
    name: 'myDaYiList',
    components: {
        fileUpload,
    },
    data() {
        return {
            imgViewSrc:"",
            imgViewVisible:false,
            dataList: [],
            userInfo:null,
            dataobj:{
                questionContent:null
            },
            zhuiWenVisible:false,
            askTextarea:'',
            fileList:[],
            isLaseAnswer:"",
            refList:[],
        }
    },
    mounted() {

        this.init();

    },
    methods: {
        imgViewFun(val){
            this.imgViewVisible = true;
            this.imgViewSrc = val;
        },
        setItemRef(el) {
          this.refList.push(el);
        },
        imgpreview() {
            // 得到所有的img节点
            const articleContent = this.$refs.articleContent
            const imgs = articleContent.querySelectorAll('img')
            const images = [];
            imgs.forEach((img, index) => {
                images.push(img.src)
                
                img.onclick = () => {
                    this.imgViewVisible = true;
                    this.imgViewSrc = img.src;
                }
            })
        },
        imgpreviewMore(index) {
            // 得到所有的img节点
            const moreDom = this.refList[index];
            const imgs = moreDom.querySelectorAll('img')
            const images = [];
            imgs.forEach((img, index) => {
                images.push(img.src)
                
                img.onclick = () => {
                    this.imgViewVisible = true;
                    this.imgViewSrc = img.src;
                }
            })
        },
        backList(){
            this.$router.back();
        },
        dayi_add(){
            let fileList = this.fileList;
            let str = "";
            if (fileList != undefined) {
                fileList.forEach((item, index) => {
                  str += item.url + ",";
                });
            }
            if (str != "") {
                str = str.substring(0, str.lastIndexOf(","));
            }
            var obj = {
                parentId:this.$route.query.id,
                questionContent:this.askTextarea,
                questionPics:str
            }
            aqAdd(obj).then((res)=>{
            
                if(res.data.code == 0){
                    this.zhuiWenVisible = false;
                    this.init();
                }else{
                    this.$message.warning(`提交失败`);
                }
            })


        },
        fileBack(fileList) {
          this.fileList = fileList;
        },
        zhuiWenClick(item){
            this.zhuiWenVisible = true;

        },
        getUrls(data){
            return data.split(",");
        },
        init(){
          
            this.userInfo = JSON.parse(localStorage.getItem("setuserInfo"));
          
            const id_query = this.$route.query.id;
         
            var obj = {
                page: this.page,
            };
            wodedayi(obj).then((res) => {
              
                let datas = res.data.data;
                datas.map((item)=>{
                    if(item.id == id_query){
                        this.dataobj = item
                    }
                })
               
                this.dataList = this.dataobj.interrogateList;
                this.dataList.map((item)=>{
                    if(item.isAnswer === 1){
                        this.isLaseAnswer = item.id;
                    }
                    
                })
                this.$nextTick(()=>{
                    this.imgpreview();
                    
                    this.dataList.map((item,index)=>{
                        this.imgpreviewMore(index);
                    })
                })
                
                
            });

        }
       
       
        
    }
}
</script>

<style lang="less">
.myDaYiList{
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #cccccc;
    text-align: left;
    img{
        
       
    }
    .videoRoom_main_right_content_noteView_btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .upload-uploader {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }
    .el-upload--picture-card {
      background-color: transparent;
      width: 80px !important;
      height: 80px !important;
      line-height: 80px;
      margin: 0 8px 8px 0;
    }
    .detail_title{
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #cccccc;
        text-align: left;
        display: flex;
        justify-content: space-between;
        span{
            margin-left: 20px;
        }

    }
    .detail_imgBox{
        width: 200px;
        height: auto;
    }
    .content_box{
        // margin-left: 20px;
        .question_box{
            margin-left: 30px;
            margin-top: 10px;
            img{
                width: 200px;
                height: auto;
               

            }


        }
        .question_box_d{
            margin-left: 30px;
            margin-top: 10px;
            img{
                width: 200px;
                height: auto;
               

            }

        }
        .question_box_z{
            margin-left: 30px;
            margin-top: 10px;
            img{
                width: 200px;
                height: auto;
               

            }


        }
        .title_box{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            margin-left: 20px;
            img{
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
            span{
                margin-left: 15px;
            }
            .detail_types{
                color: #5f7cfd;
            }
        }
        .title_box_amdin{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            margin-left: 20px;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img{
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
            span{
                margin-left: 15px;
            }
            .detail_types{
                color: #5f7cfd;
            }
            .zhuiwen_box{
                font-size: 14px;
                width: 70px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                background: #5f7cfd;
                color: white;
                border-radius: 10px;
                margin-right: 20px;
                cursor: pointer;
            }
        }
        .content_bottom{
            width: 100%;
            height: 1px;
            background: #cccccc;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .img_box{
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            img{
                margin-left: 30px;
            }
        }
    }
}

</style>